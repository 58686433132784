a {
  text-decoration: none;
}
.cat-first-sec {
  justify-content: space-between;
  align-items: center;
}
.bgwhites {
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btnsign {
  cursor: pointer;
border:none;
  color: white;
  background-color: #7AB730;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  border-radius: 50px;
}
.blacklogoimage {
  width: 180px;
  height: 75px;
}
.cx-main-layout {
  margin-left: 250px;
  padding: 90px 20px 20px 20px;
  min-height: 100vh;
  overflow-y: auto;
  background-color: var(--bg-color);
}
.cx-sidebar-main {
  width: 250px;
  height: "100%";
  background-color: RGBA(255, 255, 255, 1);
  position: fixed;
  top: 0;
  left: 0;
}
.displaynone {
  display: "flex";
  align-items: "center";
  background-color: red;
}
.cx-header-main {
  z-index: 1;
  padding-bottom: 20;
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  // background-color: red;
  border-bottom: 1px solid #b5b8bb;

  height: 70px;
  width: calc(100% - 250px);
  position: fixed;
  top: 0;
  left: 250px;
  .wrapper {
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .h-right {
      display: flex;

      .h-search {
        position: relative;
        span {
          top: 5px;
          left: 10px;
          position: absolute;
          font-size: 16px;
        }
      }
    }
    .h-left {
      h4 {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
.circlemainone {
  background-color: white; /* Default color */
}
.circlemaineight {
  background-color: white;
  // width: 300px;
  height: 350px;
}
.circlemain {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonstyle {
  margin-right: 20px;
  background-color: rgb(69, 55, 128);
  border-radius: 5px;
  border: "none";
  color: white;
  font-size: 12px;
  align-items: center;
  font-weight: 600;
  width: 90px;
  height: 30px;
  cursor: "pointer";
}
.mainscreensearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: -10px;
}

.mobile-menu {
  display: none;
  font-size: 25px;
  margin-right: 5px;
  svg {
    vertical-align: baseline;
  }
  cursor: pointer;
}
/* Media query for screens up to 1200px */
@media all and (max-width: 1200px) {
  .circlemainone {
    // background-color: #336699; /* Change color for this breakpoint */
  }
}

/* Media query for screens up to 1024px */
@media all and (max-width: 1024px) {
  .circlemainone {
    //background-color: #d72e83; /* Change color for this breakpoint */
  }
  .circlemain {
    flex-direction: column;
  }
}

/* Media query for screens up to 991px */
@media all and (max-width: 991px) {
  .circlemainone {
    //background-color: #5ca413; /* Change color for this breakpoint */
  }
}

/* Media query for screens up to 768px */
@media all and (max-width: 768px) {
  .btn-style {
    //min-width: 102px;
  }
  .background-image-container {
    display: none;
  }
  .login-wrapper-inner {
    padding: 0;
  }
  .mobile-menu {
    display: block;
  }
  .heading-main {
    display: none;
  }
  .cx-header-main {
    left: 0;
    width: 100%;
  }
  .cx-sidebar-main {
    display: none;
  }
  .cx-main-layout {
    margin-left: 0;
  }
  .circlemainone {
    // background-color: #996633; /* Change color for this breakpoint */
  }
  .circlemain {
    flex-direction: column;
    //ackground-color: rgb(225, 225, 2);
  }
  .displaynone {
    display: none;
  }
}

/* Media query for screens up to 480px */
@media all and (max-width: 480px) {
  .circlemainone {
    // background-color: #cc3300; /* Change color for this breakpoint */
  }
}

/* Media query for screens up to 360px */
@media all and (max-width: 460px) {
  .circlemainone {
  }

  .blacklogoimage {
    width: 80px;
    height: 55px;
    margin-right: 20px;
  }
  .logomaindiv {
    margin-left: 10px;
    margin-top: 15px;
  }

  .btnsign {
    cursor: pointer;

    color: white;
    background-color: #7AB730;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
    border-radius: 20px;
  }
}

/* Media query for screens above 1200px and above 480px */
@media all and (min-width: 1200px) {
  .circlemainone {
    //background-color: #000000; /* Reset to default color */
  }
  .circlemain {
    flex-direction: row;
    //background-color: #bf2121;
  }
}
.TbLogout {
  width: 20px;
  height: 20px;
}
@media all and (max-width: 480px) {
  .circlemainone {
    // background-color: #336699;
    z-index: auto;
  }
}

@media all and (max-width: 900px) {
  .circlemainone {
  }

  .blacklogoimage {
    width: 100px;
    height: 65px;
    margin-right: 20px;
  }
  .logomaindiv {
    margin-left: 10px;
    margin-top: 15px;
  }

  .btnsign {
    cursor: pointer;
    color: white;
    background-color: #7AB730;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    border: none;
    margin-top: 20px;
    width: 100%;
    border-radius: 20px;
  }
}

.table {
  margin-top: 20px; /* Adds space between form and table */
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
