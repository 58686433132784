.product-display {
  .selected-product {
    display: flex;
    gap: 120px;
    margin-bottom: 100px;
    .product-images {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
      #main-image {
        height: 300px !important;
        width: 80%;
        object-fit: cover;
      }
      .sub-images {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        img {
          height: 110px;
          width: 110px;
          object-fit: cover;
        }
      }
    }
    .selectstyle {
      height: 30px;
      width: 40%;
      border-radius: 5px;
    }
    .mainimagestyle {
      width: 100%;
      height: 10px !important;
      background-color: red;
    }
    .product-details {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      height: 800px;
      margin-bottom: 60px;

      .addinput {
        width: 100%;
        height: 40px !important;
        border-radius: 10px;
      }
      .addinputdes {
        width: 80%;
        height: 80px !important;
        border-radius: 10px;
      }
      p {
        color: grey;
      }

      .selected-product-price {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        gap: 20px;
        .selected-price {
          // color: grey;
        }
      }

      .sale-container {
        height: 25px;
        background-color: black;
        color: aqua;
        padding: 0px 0px 28px 0px;
        text-align: center;
        border-radius: 25px;
      }
      .color-selection {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h6 {
          color: grey;
        }
        .colors {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 10px;
          .color-box {
            border: 1px solid black;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
          }
        }
      }

      .counting-box {
        display: flex;
        gap: 20px;
        border: 1px solid black;
        width: 150px;
        height: 50px !important;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        button {
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
      .add-buy-buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        button {
          height: 50px;
          width: 200px;
        }
      }
      .selected-product-details {
        display: flex;
        flex-direction: column;
        gap: 15px;
        h6 {
          color: rgb(4, 4, 4);
        }
      }
    }
    .product-details::-webkit-scrollbar {
      display: none;
    }
  }
  .recommended-products {
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    h4 {
      display: flex;
      width: 100%;
    }
    .product-display {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;

      .product-card {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .product-image-container {
          position: relative;

          img.default {
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          img.hover {
            display: block;
            height: 290px;
            width: 100% !important;
            object-fit: cover;
            opacity: 0;
            transition: 0.3s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
          }

          .sale-container {
            position: absolute;
            bottom: 5px;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            background-color: black;
            border-radius: 25px;
            color: aqua;
            font-size: 15px;
          }

          &:hover {
            img.default {
              opacity: 0;
            }

            img.hover {
              opacity: 1;
            }
          }
        }

        .product-name {
          font-size: 14px;
          color: grey;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          
        }

        .product-price-details {
          display: flex;
          gap: 20px;
          align-items: center;

          .price {
            color: lightgray;
            font-size: 15px;
            font-family: sans-serif;


            span {
              margin-right: 10px;
            }
          }

          .discounted-price {
            font-size: 16px;
            font-weight: 400;

            span {
              margin-right: 10px;
            }
          }
        }
        &:hover {
          .product-name {
            h6 {
              display: inline-block;
              border-bottom: 1px solid black;
              
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .product-display {
    width: 100% !important;
    .selected-product {
      justify-content: space-between;
      .product-images {
        width: 400px;
        .sub-images {
          grid-template-columns: 1fr 1fr;
        }
      }
      .product-details {
        width: 100% !important;
      }
    }
  }
}
@media (max-width: 995px) {
  .product-display {
    max-width: 1100px !important;
    justify-content: unset;
    gap: 20px;
    .selected-product {
      gap: 20px;
      .product-images {
        // width: 50%;
        .sub-images {
          grid-template-columns: 1fr 1fr;
        }
      }
      .product-details {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 700px) {
  .product-display {
    .selected-product {
      flex-direction: column;
      .product-images {
        width: 100%;
        .sub-images {
          grid-template-columns: 1fr 1fr 1fr ;
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .product-display {
    .selected-product {
      flex-direction: column;
      .product-images {
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 550px !important;

        img {
          width: 400px !important;
        }
        .sub-images {
          grid-template-columns: 1fr 1fr;
          img {
            width: 110px !important;
            height: 110px !important;
          }
        }
      }
      .product-details {
        margin-top: 50px;
      }
    }
  }
}
@media (max-width: 415px) {
  .product-display {
    .selected-product {
      .product-images {
        .carousel {
          width: 100% !important;
          .section-images {
            margin: auto;
            width: 100%;
            img {
              width: 100% !important;
              height: 100% !important;
            }
          }
          .thumb {
            display: none;
          }
        }
        img {
          height: 100% !important;
        }
      }
      .product-details {
        margin-top: 20px;
      }
    }
  }
}
.color-box {
  width: 100px;
  padding: 10px 10px 10px 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-transform: capitalize;
}
